<template>
  <div class="ng-content-wrap">
    <div class="ng-block-head ng-block-head-lg">
      <div class="ng-block-head-sub d-print-none">
        <router-link class="back-to" to="/">
          <em class="icon ni ni-chevron-left-c mr-1"></em>
          <span>Dashboard</span>
        </router-link>
      </div>
      <div class="ng-block-between-md g-4">
        <div class="ng-block-head-content">
          <h2 class="ng-block-title fw-normal">
            {{ $route.meta.title }}
          </h2>
          <div class="ng-block-des">
            <p>
              Showing <b>{{ customers?.from || 0 }}</b> -
              <b>{{ customers?.to || 0 }}</b> of
              <b>{{ customers?.total || 0 }}</b> customers
            </p>
          </div>
        </div>
        <div class="ng-block-head-content">
          <ul class="ng-block-tools gx-3 d-print-none">
            <li>
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Type to search..."
                  v-model="search"
                  @keyup="searchCustomers"
                />
              </div>
            </li>
            <li>
              <a
                href="#newItem"
                data-toggle="modal"
                class="btn btn-white btn-dim btn-outline-primary"
              >
                <em class="icon ni ni-plus-c"></em>
                <span>
                  <span class="d-none d-sm-inline-block"> Add </span>
                  New
                </span>
              </a>
              <div class="modal fade" tabindex="-1" id="newItem">
                <div class="modal-dialog modal-lg" role="document">
                  <form class="modal-content" @submit.prevent="createCustomer">
                    <button
                      id="hideNewForm"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em class="icon ni ni-cross text-white"></em>
                    </button>

                    <div class="modal-header bg-primary">
                      <h5
                        class="modal-title text-white"
                        v-if="customer.first_name"
                      >
                        Adding {{ customer.first_name }}
                      </h5>
                      <h5 class="modal-title text-white" v-else>
                        Add New Customer
                      </h5>
                    </div>

                    <div class="modal-body form row">
                      <div class="form-group col-md-6">
                        <label>First Name</label>
                        <input
                          class="form-control"
                          v-model="customer.first_name"
                        />
                      </div>

                      <div class="form-group col-md-6">
                        <label>Last Name</label>
                        <input
                          class="form-control"
                          v-model="customer.last_name"
                        />
                      </div>

                      <div class="form-group col-md-6">
                        <label>Email Address</label>
                        <input class="form-control" v-model="customer.email" />
                      </div>

                      <div class="form-group col-md-6">
                        <label>Phone Number</label>
                        <input class="form-control" v-model="customer.phone" />
                      </div>

                      <div class="form-group col-md-6">
                        <label>Select Currency</label>
                        <Multiselect
                          :options="currencies"
                          v-model="customer.contact.currency"
                          :searchable="true"
                          placeholder="Type to search..."
                        />
                      </div>

                      <div class="form-group col-md-6">
                        <label>Shipping Agent</label>
                        <Multiselect
                          :options="agents"
                          v-model="customer.contact.agent_id"
                          trackBy="name"
                          label="name"
                          valueProp="id"
                          :searchable="true"
                          placeholder="Type to search..."
                        />
                      </div>

                      <div class="form-group col-md-6">
                        <label>Shipping Format</label>
                        <select
                          class="form-control"
                          v-model="customer.contact.format"
                        >
                          <option>Pallet</option>
                          <option>AKE</option>
                          <option>Loose</option>
                        </select>
                      </div>

                      <div class="form-group col-md-6" v-if="customer?.contact">
                        <label>Strapped In Bundles</label>
                        <input
                          class="form-control"
                          v-model="customer.contact.bundles"
                        />
                      </div>

                      <div class="form-group col-md-6 d-none">
                        <label>Address</label>
                        <input
                          class="form-control"
                          v-model="customer.contact.address_1"
                        />
                      </div>
                    </div>

                    <fieldset class="col-md-12 row">
                      <legend class="col-md-12">Contact People Details</legend>
                            <template
                              v-for="(rep, r) in customer.contact.reps"
                              :key="r"
                            >
                              <div class="form-group col-md-4">
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="customer.contact.reps[r].name"
                                  :id="`rep-customer-${r}-name`"
                                  placeholder="Full Name"
                                />
                              </div>

                              <div class="form-group col-md-6">
                                <input
                                  type="email"
                                  class="form-control"
                                  v-model="customer.contact.reps[r].email"
                                  :id="`rep-customer-${r}-email`"
                                  placeholder="Email Address"
                                />
                              </div>

                              <div class="form-group col-md-2">
                              <button class="btn btn-icon btn-round btn-danger mr-1" v-if="r > 0" @click.prevent="delRep(customer.contact.reps, r)">
                                <em class="icon ni ni-minus"></em>
                              </button>
                              <button class="btn btn-icon btn-round btn-primary" v-if="r == customer.contact.reps.length - 1" @click.prevent="addRep(customer.contact.reps)">
                                <em class="icon ni ni-plus"></em>
                              </button>
                              </div>
                            </template>
                    </fieldset>

                    <div class="modal-footer bg-light justify-content-end">
                      <button class="btn btn-primary float-right">
                        <em class="icon ni ni-save"></em>
                        <span> Save Customer Details </span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="ng-block">
      <div class="card card-bordered loading" v-if="processing">
        <div class="justify-content-center text-center">
          <div class="spinner-grow text-primary m-3" role="status">
            <span class="sr-only">Processing Data...</span>
          </div>
          <h6 class="mb-4">Processing Data...</h6>
        </div>
      </div>

      <div class="table-responsive" v-else>
        <table class="table table-tranx">
          <thead class="text-uppercase" v-if="customers.data.length">
            <tr class="tb-tnx-head">
              <th
                class="ng-tb-col ng-tb-col-check d-print-none"
                tabindex="0"
                aria-controls="DataTables_Table_1"
                rowspan="1"
                colspan="1"
                aria-sort="ascending"
                aria-label=": activate to sort column descending"
              >
                <div
                  class="
                    custom-control custom-control-sm custom-checkbox
                    notext
                  "
                >
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="uid"
                    @click="selectAll"
                  />
                  <label class="custom-control-label" for="uid"></label>
                </div>
              </th>
              <th class="text-truncate align-middle">
                <strong>Customer</strong>
              </th>
              <th class="text-center align-middle">Currency</th>
              <th class="text-center align-middle">Shipping Agent</th>
              <th class="text-center align-middle">Shipping Format</th>
              <th class="text-center align-middle">Bundles</th>
              <th class="tb-tnx-action d-print-none">CRM</th>
            </tr>
          </thead>

          <tbody>
            <template v-if="customers.data.length > 0">
              <tr class="tb-tnx-item" v-for="c in customers.data" :key="c.id">
                <td
                  class="
                    ng-tb-col ng-tb-col-check
                    align-middle
                    d-none d-sm-flex d-print-none
                  "
                >
                  <div
                    class="
                      custom-control custom-control-sm custom-checkbox
                      notext
                    "
                  >
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      :id="`c${c.id}`"
                      :value="c.id"
                      v-model="selected"
                    />
                    <label
                      class="custom-control-label"
                      :for="`c${c.id}`"
                    ></label>
                  </div>
                </td>
                <td class="text-left">
                  <div class="user-card">
                    <div class="user-avatar bg-primary">
                      <img :src="c.avatar" :alt="c.name" />
                    </div>
                    <div class="user-info">
                      <span class="lead-text">
                        {{ c.name }}
                      </span>
                      <span class="sub-text">
                        {{ c.email }}
                      </span>
                    </div>
                  </div>
                </td>

                <td class="align-middle" style="min-width: 180px">
                  <div class="form-group" v-if="c.contact">
                    <Multiselect
                      :options="currencies"
                      v-model="c.contact.currency"
                      :searchable="true"
                      placeholder="Type to search..."
                      class="border-0"
                      @change="updateCustomer(c)"
                    />
                  </div>
                </td>
                <td class="align-middle" style="min-width: 220px">
                  <div class="form-group" v-if="c.contact">
                    <Multiselect
                      :options="agents"
                      v-model="c.contact.agent_id"
                      trackBy="name"
                      label="name"
                      valueProp="id"
                      :searchable="true"
                      placeholder="Type to search..."
                      class="border-0"
                      @change="updateCustomer(c)"
                    />
                  </div>
                </td>
                <td class="align-middle" style="min-width: 180px">
                  <div class="form-group" v-if="c.contact">
                    <Multiselect
                      :options="formats"
                      v-model="c.contact.format"
                      placeholder="Type to search..."
                      class="border-0"
                      @change="updateCustomer(c)"
                    />
                  </div>
                </td>
                <td class="align-middle text-truncate">
                  <div class="form-group" v-if="c.contact">
                    <input
                      class="form-control border-0"
                      v-model="c.contact.bundles"
                      @keyup="updateCustomer(c)"
                    />
                  </div>
                </td>

                <td
                  class="tb-tnx-action justify-content-end d-print-none"
                  data-title=""
                >
                  <a
                    class="text-soft btn btn-icon btn-trigger"
                    data-toggle="modal"
                    aria-expanded="false"
                    :href="`#modal-update-${c.id}`"
                    @click="setCustomer(c)"
                  >
                    <em class="icon ni ni-user-alt"></em>
                  </a>
                </td>

                <div
                  class="modal fade"
                  :id="'modal-update-' + c.id"
                  tabindex="-1"
                  role="dialog"
                  :aria-labelledby="'modal-title-' + c.id"
                  aria-hidden="true"
                >
                  <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                      <div class="modal-header bg-primary">
                        <h5
                          class="modal-title text-white"
                          :id="'modal-title-' + c.id"
                        >
                          Edit {{ buyer.name }}
                        </h5>
                        
                        <button
                          id="hideEditForm"
                          class="close"
                          type="button"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <em class="icon ni ni-eye-off text-white"></em>
                        </button>
                      </div>
                      <div class="modal-body text-left">
                        <form
                          class="form row"
                          @submit.prevent="updateCustomer(buyer)"
                        >
                          <div class="form-group col-md-12">
                            <label>Company Name</label>
                            <input
                              class="form-control"
                              v-model="buyer.first_name"
                            />
                          </div>

                          <div class="form-group col-md-6">
                            <label>Last Name</label>
                            <input
                              class="form-control"
                              v-model="buyer.last_name"
                            />
                          </div>

                          <div class="form-group col-md-6">
                            <label>Email Address</label>
                            <input class="form-control" v-model="buyer.email" />
                          </div>

                          <div class="form-group col-md-6">
                            <label>Phone Number</label>
                            <input class="form-control" v-model="buyer.phone" />
                          </div>

                          <div
                            class="form-group col-md-6"
                            v-if="buyer?.contact"
                          >
                            <label>Select Currency</label>
                            <Multiselect
                              :options="currencies"
                              v-model="buyer.contact.currency"
                              trackBy="title"
                              label="title"
                              valueProp="id"
                              :searchable="true"
                              placeholder="Type to search..."
                            />
                          </div>

                          <div class="form-group col-md-6">
                            <label>Shipping Agent</label>
                            <Multiselect
                              :options="agents"
                              v-model="buyer.contact.agent_id"
                              trackBy="name"
                              label="name"
                              valueProp="id"
                              :searchable="true"
                              placeholder="Type to search..."
                            />
                          </div>

                          <div class="form-group col-md-6">
                            <label>Shipping Format</label>
                            <select
                              class="form-control"
                              v-model="buyer.contact.format"
                            >
                              <option>Pallet</option>
                              <option>AKE</option>
                              <option>Loose</option>
                            </select>
                          </div>

                          <div
                            class="form-group col-md-6"
                            v-if="buyer?.contact"
                          >
                            <label>Strapped In Bundles</label>
                            <input
                              class="form-control"
                              v-model="buyer.contact.bundles"
                            />
                          </div>

                          <div
                            class="form-group col-md-6"
                            v-if="buyer?.contact"
                          >
                            <label>Address</label>
                            <input
                              class="form-control"
                              v-model="buyer.contact.address_1"
                            />
                          </div>

                          <fieldset class="col-md-12 row" v-if="buyer?.contact">
                            <legend class="col-md-12">
                              Contact People Details
                            </legend>

                            <template
                              v-for="(rep, r) in buyer.contact.reps"
                              :key="r"
                            >
                              <div class="form-group col-md-4">
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="buyer.contact.reps[r].name"
                                  :id="`rep-${c.id}-${r}-name`"
                                  placeholder="Full Name"
                                />
                              </div>

                              <div class="form-group col-md-6">
                                <input
                                  type="email"
                                  class="form-control"
                                  v-model="buyer.contact.reps[r].email"
                                  :id="`rep-${c.id}-${r}-email`"
                                  placeholder="Email Address"
                                />
                              </div>
                              <div class="form-group col-md-2">
                              <button class="btn btn-icon btn-round btn-danger mr-1" v-if="r > 0" @click.prevent="delRep(buyer.contact.reps, r)">
                                <em class="icon ni ni-minus"></em>
                              </button>
                              <button class="btn btn-icon btn-round btn-primary" v-if="r == buyer.contact.reps.length - 1" @click.prevent="addRep(buyer.contact.reps)">
                                <em class="icon ni ni-plus"></em>
                              </button>
                              </div>
                            </template>
                          </fieldset>

                          <div class="form-group col-md-12">
                            <button class="btn btn-primary float-right">
                              <em class="icon ni ni-save"></em>
                              <span> Update {{ buyer.name }} </span>
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </tr>
            </template>

            <template v-else>
              <tr class="tb-tnx-item alert alert-danger">
                <td colspan="7" class="text-center">
                  <h4>
                    <em class="icon ni ni-alert-c text-danger mr-2"></em>
                    No record to display...
                  </h4>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>

      <paginate
        :data="customers"
        @changed="fetchCustomers"
        v-if="customers.data.length"
      />
    </div>
  </div>
</template>

<script lang="ts">
import http from "../../../../common/services/http";
import {
  computed,
  defineComponent,
  ref,
  reactive,
  onMounted,
  onBeforeMount,
} from "vue";
import Input from "common/components/Input.vue";

export default defineComponent({
  setup(props) {
    const headers = {
      name: "Customer",
      // phone: "Phone Number",
    };

    const currencies = ref({
      euro: "Euro",
      pound: "Sterling Pound",
      shilling: "Kenyan Shilling",
      dollar: "US Dollar",
    });

    const formats = ref({ Pallet: "Pallet", AKE: "AKE", Loose: "Loose" });

    const customers = ref({
      data: [],
      first_page: 1,
      last_page: 1,
    });

    const customer = reactive({
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      role: { id: "customer", title: "Customer" },
      contact: {
        name: "",
        email: "",
        phone: "",
        currency: "pound",
        address_1: "Address",
        bundles: 0,
        format: "Pallet",
        agent_id: 0,
        reps: [
          { name: "", email: "" }
        ],
      },
    });

    const buyer = ref({
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      role: { id: "customer", title: "Customer" },
      contact: {
        name: "",
        email: "",
        phone: "",
        currency: "pound",
        address_1: "Address",
        bundles: 0,
        format: "Pallet",
        agent_id: 0,
        reps: [
          { name: "", email: "" }
        ],
      },
    });

    const agents = ref([]);
    const selected = ref([]);

    const search = ref("");

    const processing = ref(false);
    const created = ref(false);
    const updated = ref(false);

    function fetchCustomers(page = 1) {
      processing.value = true;
      http
        .get(`/api/users?role=customer&page=${page}`)
        .then((res) => {
          customers.value = res.data;
        })
        .finally(() => {
          processing.value = false;
        });
    }

    function fetchAgents() {
      processing.value = true;
      http.get(`/api/users?role=agent&fetch=all`).then((res) => {
        agents.value = res.data;
        processing.value = false;
      });
    }

    function findAgent(id: any) {
      return agents.value.find((a: any) => a.id == id);
    }

    function searchCustomers(e: any) {
      processing.value = true;
      http.get(`/api/users?role=customer&s=${search.value}`).then((res) => {
        customers.value = res.data;
        processing.value = false;
      });
    }

    function createCustomer() {
      http
        .post("/api/users", customer)
        .then((res) => {
          if (res.data.success) {
            created.value = true;
          }
        })
        .finally(() => {
          customer.first_name = "";
          customer.last_name = "";
          customer.email = "";
          customer.phone = "";
          customer.role = { id: "customer", title: "Customer" };
          customer.contact = {
            name: "",
            email: "",
            phone: "",
            currency: "pound",
            address_1: "Address",
            bundles: 0,
            format: "Pallet",
            agent_id: 0,
            reps: [
              { name: "", email: "" },
            ],
          };
          document.getElementById("hideNewForm")?.click();
          fetchCustomers();
        });
    }

    function setCustomer(u: any) {
      if (!u.contact.reps) {
        Object.defineProperty(u.contact, "reps", {
          value: [
      { name: "", email: "" },
    ],
          writable: true,
        });
      }

      buyer.value = u;
    }

    function updateCustomer(c: any) {
      c._method = "PUT";
      http
        .post(`/api/users/${c.id}`, c)
        .then((res) => {
          document.getElementById("hideEditForm")?.click();
          if (res.data.success) {
            updated.value = true;
          }
        })
        .finally(() => {
          fetchCustomers();
        });
    }

    function addRep(u: any) {
      u.push({ name: "", email: "" })
    }

    function delRep(u: any, i: any) {
      u.splice(i, 1)
    }

    function deleteCustomer(id: any) {
      http.post(`/api/users/${id}`, { _method: "DELETE" }).then((res) => {
        fetchCustomers();
      });
    }

    onBeforeMount(() => {
      fetchAgents();
    });

    onMounted(() => {
      fetchCustomers();
    });

    return {
      headers,
      fetchCustomers,
      processing,
      agents,
      customers,
      createCustomer,
      created,
      customer,
      updateCustomer,
      updated,
      deleteCustomer,
      search,
      searchCustomers,
      currencies,
      selected,
      findAgent,
      formats,
      buyer,
      setCustomer,
      addRep,
      delRep
    };
  },
});
</script>
