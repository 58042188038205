
import http from "../../../../common/services/http";
import {
  computed,
  defineComponent,
  ref,
  reactive,
  onMounted,
  onBeforeMount,
} from "vue";
import Input from "common/components/Input.vue";

export default defineComponent({
  setup(props) {
    const headers = {
      name: "Customer",
      // phone: "Phone Number",
    };

    const currencies = ref({
      euro: "Euro",
      pound: "Sterling Pound",
      shilling: "Kenyan Shilling",
      dollar: "US Dollar",
    });

    const formats = ref({ Pallet: "Pallet", AKE: "AKE", Loose: "Loose" });

    const customers = ref({
      data: [],
      first_page: 1,
      last_page: 1,
    });

    const customer = reactive({
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      role: { id: "customer", title: "Customer" },
      contact: {
        name: "",
        email: "",
        phone: "",
        currency: "pound",
        address_1: "Address",
        bundles: 0,
        format: "Pallet",
        agent_id: 0,
        reps: [
          { name: "", email: "" }
        ],
      },
    });

    const buyer = ref({
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      role: { id: "customer", title: "Customer" },
      contact: {
        name: "",
        email: "",
        phone: "",
        currency: "pound",
        address_1: "Address",
        bundles: 0,
        format: "Pallet",
        agent_id: 0,
        reps: [
          { name: "", email: "" }
        ],
      },
    });

    const agents = ref([]);
    const selected = ref([]);

    const search = ref("");

    const processing = ref(false);
    const created = ref(false);
    const updated = ref(false);

    function fetchCustomers(page = 1) {
      processing.value = true;
      http
        .get(`/api/users?role=customer&page=${page}`)
        .then((res) => {
          customers.value = res.data;
        })
        .finally(() => {
          processing.value = false;
        });
    }

    function fetchAgents() {
      processing.value = true;
      http.get(`/api/users?role=agent&fetch=all`).then((res) => {
        agents.value = res.data;
        processing.value = false;
      });
    }

    function findAgent(id: any) {
      return agents.value.find((a: any) => a.id == id);
    }

    function searchCustomers(e: any) {
      processing.value = true;
      http.get(`/api/users?role=customer&s=${search.value}`).then((res) => {
        customers.value = res.data;
        processing.value = false;
      });
    }

    function createCustomer() {
      http
        .post("/api/users", customer)
        .then((res) => {
          if (res.data.success) {
            created.value = true;
          }
        })
        .finally(() => {
          customer.first_name = "";
          customer.last_name = "";
          customer.email = "";
          customer.phone = "";
          customer.role = { id: "customer", title: "Customer" };
          customer.contact = {
            name: "",
            email: "",
            phone: "",
            currency: "pound",
            address_1: "Address",
            bundles: 0,
            format: "Pallet",
            agent_id: 0,
            reps: [
              { name: "", email: "" },
            ],
          };
          document.getElementById("hideNewForm")?.click();
          fetchCustomers();
        });
    }

    function setCustomer(u: any) {
      if (!u.contact.reps) {
        Object.defineProperty(u.contact, "reps", {
          value: [
      { name: "", email: "" },
    ],
          writable: true,
        });
      }

      buyer.value = u;
    }

    function updateCustomer(c: any) {
      c._method = "PUT";
      http
        .post(`/api/users/${c.id}`, c)
        .then((res) => {
          document.getElementById("hideEditForm")?.click();
          if (res.data.success) {
            updated.value = true;
          }
        })
        .finally(() => {
          fetchCustomers();
        });
    }

    function addRep(u: any) {
      u.push({ name: "", email: "" })
    }

    function delRep(u: any, i: any) {
      u.splice(i, 1)
    }

    function deleteCustomer(id: any) {
      http.post(`/api/users/${id}`, { _method: "DELETE" }).then((res) => {
        fetchCustomers();
      });
    }

    onBeforeMount(() => {
      fetchAgents();
    });

    onMounted(() => {
      fetchCustomers();
    });

    return {
      headers,
      fetchCustomers,
      processing,
      agents,
      customers,
      createCustomer,
      created,
      customer,
      updateCustomer,
      updated,
      deleteCustomer,
      search,
      searchCustomers,
      currencies,
      selected,
      findAgent,
      formats,
      buyer,
      setCustomer,
      addRep,
      delRep
    };
  },
});
